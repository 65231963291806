/* Demo Styles */
// Add spacing to Boostrap components for demo purpose
.template-demo {
  > .btn {
    @extend .mt-2;
    @extend .mr-2;
  }

  > .btn-toolbar {
    @extend .mt-2;
    @extend .mr-2;
  }

  > .btn-group {
    @extend .mt-2;
    @extend .mr-2;

    .btn {
      margin: 0;
    }
  }

  .progress {
    margin-top: 1.5rem;
  }

  .circle-progress {
    @extend .mt-2;
  }

  > h2,
   > h3,
   > h4,
   > h5,
   > h6,
  > h1 {
    border-top: 1px solid $border-color;
    padding: 0.5rem 0 0;
  }

  .ul-slider {
    &.noUi-horizontal {
      margin-top: 2rem;
    }

    &.noUi-vertical {
      margin-right: 2rem;
    }
  }

  .dropdown {
    display: inline-block;
    @extend .mr-2;
    margin-bottom: 0.5rem;
  }

  nav {
    .breadcrumb {
      margin-bottom: 1.375rem;
    }

    &:last-child {
      .breadcrumb {
        margin-bottom: 0;
      }
    }
  }

  .editable-form {
    > .form-group {
      border-bottom: 1px solid $border-color;
      padding-bottom: 0.8rem;
      margin-bottom: 0.8rem;
    }
  }

  .circle-progress {
    padding: 15px;
  }

  .circle-progress-block {
    @extend .mb-3;
    @extend .px-2;
  }
}

.demo-modal {
  position: static;
  display: block;

  .modal-dialog {

    &.modal-lg {
      max-width: 100%;
    }
  }
}

.loader-demo-box {
  @extend .border;
  @extend .border-secondary;
  @extend .rounded;
  width: 100%;
  height: 200px;
  @extend .d-flex;
  @extend .align-items-center;
}

.documentation {
  padding-top: 0;
  max-width: 100%;
  .content-wrapper {
    padding-top: 0;
    min-height: calc(100vh - #{$footer-height});
  }
}
.doc-table-contents,
.doc-header {
  position: fixed;
}
.doc-header {
  padding-top: 24px;
  padding-bottom: 24px;
  z-index: 2;
  background: $content-bg;
}

.doc-content {
  padding-top: 157px;
}

.doc-table-contents {
  overflow: auto;
  max-height: calc(100% - 157px);
  ul {
    li {
      margin-top: 10px;
      &:before {
        font-size: .5rem;
      }
    }
  }
}

.doc-credits {
  ul {
    li {
      margin-bottom: 10px;
      a {
        margin-left: .25rem;
      }
    }
  }
}

.demo-vector-map {
  width: 100%;
  height: 500px;
}

.landing-page {
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
  }
  .content-wrapper {
    width: calc(100% - 40px);
    margin: auto;
    max-width: inherit;
    padding: 0;
  }
    .banner-image {
        width: 100%;
    }
    .bootstrapdash-demo-footer {
        padding: 5rem;
    }
    .navbar {
        background: $white;
        border-bottom: 1px solid $border-color;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        @media (min-width: 992px) {
            padding: .5rem 5rem;
            .navbar-collapse {
                margin-left: 13rem;
            }
        }
    }
    .bootstrapdash-demo-variations {
        padding: 2rem 3.75rem;
    }
    .product-variation-item {
        text-align: center;
        a {
            text-decoration: none;
            img {
                width: 100%;
                border: 1px solid #f3f3f3;
            }
            h6 {
                text-align: left;
                color: $black;
                margin-top: 2rem;
                margin-bottom: 0;
            }
        }
        &.item-more {
            a {
                border: 1px solid #f3f3f3;
                margin-bottom: 24px;
            }
        }
    }
    .grid-margin {
        margin-bottom: 30px;
    }
    .btn {
        font-size: 14px;
        font-weight: normal;
    }
    .bootstrapdash-features {
        .bootstrapdash-feature-item {
            padding: 2rem;
            @media (min-width: 768px) {
                border-left: 1px solid rgba($white, .22);
                border-bottom: 1px solid rgba($white, .22);
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    padding-top: 0;
                }
                &:nth-child(9),
                &:nth-child(10),
                &:nth-child(11),
                &:nth-child(12) {
                    padding-bottom: 0;
                    border-bottom: 0;
                }
                &:nth-child(4n+1) {
                    border-left: 0;
                    padding-left: 0;
                }
                &:nth-child(4n) {
                    padding-right: 0;
                }
            }
            h6 {
                i {
                    font-size: 30px;
                    margin-right: 2px;
                }
            }
            p {
                margin-bottom: 0;
            }
        } 
    }

    @keyframes slideInFromBottom {
        0% {
          transform: translateY(100%);
        }
        100% {
          transform: translateY(0);
        }
      }
      
    #main-demo-image {  
        /* This section calls the slideInFromLeft animation we defined above */
        animation: .5s ease-out 0s 1 slideInFromBottom;
    }

}